import React from 'react';
import Language from '@Components/Language';
import { Dropdown } from 'primereact/dropdown';
import { EditButtonTemplate } from '@App/Components';
import { IData } from '@Types/IData';
import { IDropDown } from '@App/Types';
import { Calendar } from 'primereact/calendar';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import {ItemPickList} from "@Pages/Portal/FormGroups/FormGroup";

export const preservationOptions = {
  preserveState: true,
  preserveScroll: true,
};

// Record Status

// Document Policy
export const documentTypes: StatusData[] = [
  {
    value: 'document',
    label: Language.get('Document'),
  },
  {
    value: 'policy',
    label: Language.get('Policy'),
  },
];

// Yes no drop down options
export const yesNoSelections: StatusData[] = [
  {
    value: 'yes',
    label: Language.get('Yes'),
  },
  {
    value: 'no',
    label: Language.get('No'),
  },
];

// route status dropdown options
export const routePlanStatus: IDropDown[] = [
  { name: Language.get('approved'), record_id: 'approved' },
  { name: Language.get('archive'), record_id: 'archive' },
  { name: Language.get('cancelled'), record_id: 'cancelled' },
  { name: Language.get('complete'), record_id: 'complete' },
  { name: Language.get('draft'), record_id: 'draft' },
  { name: Language.get('escalated'), record_id: 'escalated' },
  { name: Language.get('paused'), record_id: 'paused' },
  { name: Language.get('pending'), record_id: 'pending' },
  { name: Language.get('rejected'), record_id: 'rejected' },
  { name: Language.get('start_expired'), record_id: 'start_expired' },
  { name: Language.get('started'), record_id: 'started' },
  { name: Language.get('withdrawn'), record_id: 'withdrawn' },
];

// convert string to date object
export const convertToDate = (date: string) => (date ? new Date(date) : undefined);

// display toast message
export const showNotificationToast = (toast: any, errors: any, flashMessage: any) => {
  let severity;
  let summary;
  let detail;

  if (errors && Object.keys(errors).length > 0) {
    severity = 'error';
    summary = 'Something went wrong';
    detail = (
      <ul className="m-0 p-0 mt-4">
        {Object.keys(errors).map((field) => (
          <li key={field} className="mt-2">
            {errors[field]}
          </li>
        ))}
      </ul>
    );
  } else if (flashMessage) {
    severity = 'success';
    summary = 'Success';
    detail = flashMessage;
  }

  if ((errors && Object.keys(errors).length !== 0) || flashMessage) {
    toast.current?.show({
      severity,
      summary,
      detail,
      life: 6000,
    });
  }
};

export const recordStatusOptions: StatusData[] = [
  { label: Language.get('Active'), value: 'active' },
  { label: Language.get('Archived'), value: 'archive' },
  { label: Language.get('Disabled'), value: 'disable' },
];

export const getRecordStatusOptions = (tableType: string): StatusData[] => {
  switch (tableType) {
    case 'tableAccessGroup':
      return [
        { label: Language.get('Active'), value: 'active' },
        { label: Language.get('Archived'), value: 'archive' },
      ];
    // Add more cases as needed for different tables
    default:
      return recordStatusOptions; // Default options
  }
};

export const statusRowFilterTemplate = (options: {
  field: string;
  value: string;
  filterCallback: (arg0: any, arg1: any) => void;
  filterApplyCallback: (value: string) => void;
  index: any;
  tableType?: string;
  showClear?: boolean;
}) => {
  const item = (option: StatusData) => {
    return <span className={`record-status status-${option.value.toLowerCase()}`}>{Language.get(option.label)}</span>;
  };

  const optionsList = [
    { label: Language.get('All'), value: 'all' },
    ...getRecordStatusOptions(options.tableType || 'default'),
  ];

  return (
    <Dropdown
      value={options.value}
      options={optionsList}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={item}
      placeholder={Language.get('Select Status')}
      className="p-column-filter"
      showClear={options?.showClear ?? true}
      style={{ minWidth: '12rem' }}
    />
  );
};

export const companyIdFilterTemplate = (
  options: {
    value: string;
    filterCallback: (arg0: any, arg1: any) => void;
    filterApplyCallback: (value: string) => void;
    index: any;
  },
  companies: IData[]
) => (
  <Dropdown
    optionLabel="name"
    optionValue="record_id"
    options={companies}
    value={options.value}
    filter
    placeholder={Language.get('Company')}
    onChange={(e) => {
      options.filterApplyCallback(e.value);
    }}
    // itemTemplate={item}
    className="p-column-filter"
    showClear
    style={{ minWidth: '12rem' }}
  />
);

export const dropdownFilterTemplate = (
  options: {
    field: string;
    value: string;
    filterCallback: (arg0: any, arg1: any) => void;
    filterApplyCallback: (value: string) => void;
    index: any;
  },
  props: {
    options: IData[];
    placeholder: string;
    optionValue?: string;
    optionLabel?: string;
  }
) => (
  <Dropdown
    optionLabel={props.optionLabel || 'name'}
    optionValue={props.optionValue || 'remote_id'}
    options={props.options}
    value={options.value}
    filter
    placeholder={props.placeholder}
    onChange={(e) => {
      options.filterCallback(e.value, options.index);
    }}
    className="p-column-filter"
    showClear
    style={{ minWidth: '12rem' }}
  />
);

export const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      selectionMode="range"
      showButtonBar
      readOnlyInput
      hideOnRangeSelection
      placeholder={Language.get('Date From - To')}
    />
  );
};

export const documentTypeFilterTemplate = (options: {
  value: string;
  filterCallback: (arg0: any, arg1: any) => void;
  filterApplyCallback: (value: string) => void;
  index: any;
}) => (
  <Dropdown
    value={options.value}
    options={documentTypes}
    onChange={(e) => options.filterApplyCallback(e.value)}
    placeholder={Language.get('Select Document Type')}
    className="p-column-filter"
    showClear
    style={{ minWidth: '12rem' }}
  />
);

export const yesNoFilterTemplate = (
  options: {
    field: string;
    value: string;
    filterCallback: (arg0: any, arg1: any) => void;
    filterApplyCallback: (value: string) => void;
    index: any;
  },
  placeholder: string
) => (
  <Dropdown
    value={options.value}
    options={yesNoSelections}
    onChange={(e) => options.filterCallback(e.value, options.index)}
    placeholder={placeholder}
    className="p-column-filter"
    showClear
    style={{ minWidth: '12rem' }}
  />
);

export const editRowButton = (params: IData, routeName: string) => {
  return (
    <div className="flex justify-content-start">
      <EditButtonTemplate edit_route={route(routeName, params)} />
    </div>
  );
};

export const formatNumber = (num: number) => {
  if (Number(num) >= 1000) {
    return `${(Number(num) / 1000).toFixed(0)} km`;
  }

  return `${Number(num).toString()} m`;
};

export const itemPickOnlyTemplate = (item: ItemPickList, prop: 'title' | 'label' | 'name' = 'title') => (
  <div className="flex flex-wrap p-2 align-items-center gap-3">
    <div className="flex-1 flex flex-column gap-2">
      <span>{item?.[prop]}</span>
    </div>
    <span className="font-bold text-900">
      <i className="pi pi-bell" />
    </span>
  </div>
);


export const handleDownload = (fileUrl: string | undefined) => {
  if (fileUrl) {
    // Create an anchor element and set the download attribute
    const link: any = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = fileUrl.split('/').pop();
    link.click();
  }
};

export const jms1Link = (urlPath: string) => {
  const jms1Host = import.meta.env.VITE_REACT_APP_JMS_OLD_BASE_URL || '';
  const cleanHost = jms1Host.replace(/\/$/, ''); // Remove trailing slash
  const cleanPath = urlPath.replace(/^\//, ''); // Remove leading slash
  return `${cleanHost}/${cleanPath}`;
};